import { cookieKeys } from '../../pages/_constants';
import { setBrowserCookie } from '../../utils';

export default function ChangeLanguage() {
  return (
    <>
      <button
        type="button"
        onClick={e => {
          e.preventDefault();
          setBrowserCookie(cookieKeys.locale, 'pl');
          window.location.reload();
        }}>
        <img
          className="underline cursor-pointer"
          src="https://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg"
          alt="język polski"
          width="35"
          height="35"
        />
      </button>

      <button
        type="button"
        onClick={e => {
          e.preventDefault();
          setBrowserCookie(cookieKeys.locale, 'en');
          window.location.reload();
        }}>
        <img
          className="underline cursor-pointer"
          src="https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg"
          alt="change page language to english"
          width="35"
          height="35"
        />
      </button>
    </>
  );
}
